import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { Asset, QueryParams } from 'types';

const url = `${env.apiUrl}/assets`;

const getAll = async (): Promise<Asset[]> => (await axios.get(url)).data;

const getAllMy = async (): Promise<Asset[]> =>
  (await axios.get(`${url}/my`)).data;

const getAllAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Asset[]> =>
    (await axios.get(`${url}/${role}`, { params })).data;

const getAllMerchantsAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Asset[]> =>
    (await axios.get(`${url}/${role}/merchants`, { params })).data;

const getAllTradersAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Asset[]> =>
    (await axios.get(`${url}/${role}/traders`, { params })).data;

const getAllPlatform = async (): Promise<Asset[]> =>
  (await axios.get(`${url}/platform`)).data;

const getMerchantAllMy = async (): Promise<Asset[]> =>
  (await axios.get(`${url}/merchant/my`)).data;

const getAllShops = async (): Promise<Asset[]> =>
  (await axios.get(`${url}/shops`)).data;

const withdrawInsuranceDeposit = async (): Promise<any> =>
  (await axios.post(`${url}/insurance-deposit-withdrawal`)).data;

const getAllP2PProviders = async (
  params?: Pick<QueryParams, 'filters'>,
): Promise<Asset[]> =>
  (await axios.get(`${url}/p2p-providers`, { params })).data;

export const assetsApi = {
  getAll,
  getAllMy,
  getAllAsRole,
  getAllMerchantsAsRole,
  getAllTradersAsRole,
  getAllShops,
  getAllPlatform,
  getAllP2PProviders,
  getMerchantAllMy,
  withdrawInsuranceDeposit,
};
