import { Warning as WarningIcon } from '@mui/icons-material';
import i18next from 'i18next';
import { find, includes, map } from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';

import {
  ContactInfo,
  CopyTextId,
  DataGridColumnDefinition,
  DateLabel,
  Money,
  PaymentTypeLabel,
  RequisitesInfo,
  UserInfo,
} from 'components';
import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  DateFormat,
  FilterDefinitionType,
  OrderStatus,
  OrderStatusDetails,
  PayoutOrderStatus,
  PayoutOrderStatusDetails,
  UserRole,
} from 'enums';
import {
  OrderAmountColumn,
  OrderCancelledToDisputeColumn,
  OrderResolveDisputeColumn,
  OrderStatusColumn,
  AutomationStatus,
  P2PProviderStatus,
} from 'features';
import {
  PayoutOrderStatusColumn,
  PayoutOrderCancelledToDisputeColumn,
  PayoutOrderDisputeColumn,
  PayoutOrderValidationColumn,
} from 'features/payout-orders';
import {
  FilterDefinition,
  OrderFilters,
  PayinOrder,
  PayoutOrder,
  Order,
  CommonFilters,
  PayoutOrderFilters,
} from 'types';
import { formatUtils } from 'utils';

const getCommonFilters = (
  filters: CommonFilters,
): FilterDefinition<OrderFilters>[] => {
  const { t } = i18next;
  return [
    {
      label: t('filters.date_from'),
      name: 'from',
      type: FilterDefinitionType.DateTime,
      startOf: 'minute',
      maxDate: filters?.to ? moment(filters?.to) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('filters.date_to'),
      name: 'to',
      type: FilterDefinitionType.DateTime,
      endOf: 'minute',
      minDate: filters?.from ? moment(filters?.from) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('features.orders.filters.order_id'),
      name: 'orderId',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
  ];
};

const getCommonPayoutOrderFilters = (
  filters: CommonFilters,
): FilterDefinition<PayoutOrderFilters>[] => {
  const { t } = i18next;
  return [
    {
      label: t('filters.date_from'),
      name: 'from',
      type: FilterDefinitionType.DateTime,
      startOf: 'minute',
      maxDate: filters?.to ? moment(filters?.to) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('filters.date_to'),
      name: 'to',
      type: FilterDefinitionType.DateTime,
      endOf: 'minute',
      minDate: filters?.from ? moment(filters?.from) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('features.orders.filters.order_id'),
      name: 'id',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
  ];
};

const getCallbackUrlStatusLabel = (status: CallbackUrlStatus) => {
  const { t } = i18next;
  const key = `features.orders.callback_url_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getStatusLabel = (status: string) => {
  const { t } = i18next;
  const key = `order_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getStatusFilterDefinition = (
  statuses: (OrderStatus | PayoutOrderStatus)[],
): FilterDefinition<OrderFilters> => {
  const { t } = i18next;
  return {
    label: t('features.orders.filters.status'),
    name: 'statuses',
    type: FilterDefinitionType.MultiSelect,
    options: map(statuses, (status) => ({
      value: status,
      label: getStatusLabel(status),
    })),
    getDisplayName: (value: OrderStatus[]) =>
      map(value, (status) => getStatusLabel(status)).join(', '),
  };
};

const getStatusDetailsLabel = (details: OrderStatusDetails) => {
  const { t } = i18next;
  const key = `order_status_details.${details}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getPayoutStatusDetailsLabel = (details: PayoutOrderStatusDetails) => {
  const { t } = i18next;
  const key = `order_status_details.${details}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const hadStatus = (order: Order, status: string) =>
  order.status === status ||
  order.statuses?.map((history) => history.status).includes(status);

const isCustomerPaid = (order: PayinOrder) =>
  hadStatus(order, OrderStatus.TraderConfirm) ||
  hadStatus(order, OrderStatus.Completed);

const isTraderPaid = (order: PayoutOrder) =>
  hadStatus(order, PayoutOrderStatus.Completed);

const renderP2PProviderOrderRequisites = (order: PayinOrder) => {
  if (order?.p2pProviderOrder?.requisites) {
    const p2pProviderRequisites = order.p2pProviderOrder.requisites as any;
    return (
      <RequisitesInfo
        cardholder={p2pProviderRequisites.cardholder}
        bankId={p2pProviderRequisites.bankId || order.bankId}
        bankCode={p2pProviderRequisites.bank}
        paymentTypeId={
          p2pProviderRequisites.paymentTypeId || order.paymentTypeId
        }
        cardInfo={p2pProviderRequisites.cardInfo}
        phone={p2pProviderRequisites.phone}
        swiftBic={p2pProviderRequisites.swiftBic}
        bic={p2pProviderRequisites.bic}
        email={p2pProviderRequisites.email}
        idCard={p2pProviderRequisites.idCard}
        beneficiaryName={p2pProviderRequisites.beneficiaryName}
        accountNumber={p2pProviderRequisites.accountNumber}
        name={p2pProviderRequisites.name}
        expirationDate={p2pProviderRequisites.expirationDate}
      />
    );
  }
};

const renderOrderRequisites = (order: PayinOrder) => {
  if (order?.requisites) {
    return <RequisitesInfo requisite={order.requisites} />;
  }

  if (order?.p2pProviderOrder?.requisites) {
    return renderP2PProviderOrderRequisites(order);
  }
};

const getStatusDate = (order: Order, status: string) =>
  order.status === status
    ? order.statusAt
    : find(order.statuses, { status })?.statusAt;

const getPayoutOrderColumns = (
  additionalColumns: AdditionalOrderColumn[],
  role: UserRole,
): DataGridColumnDefinition<PayoutOrder>[] =>
  getOrderColumns(additionalColumns, 'payout', { role });

const getPayinOrderColumns = (
  additionalColumns: AdditionalOrderColumn[],
  role: UserRole,
): DataGridColumnDefinition<PayinOrder>[] =>
  getOrderColumns(additionalColumns, 'payin', { role });

const getOrderColumns = (
  additionalColumns: AdditionalOrderColumn[],
  operation: 'payin' | 'payout',
  params?: { role?: UserRole },
): DataGridColumnDefinition<Order>[] => {
  const { t } = i18next;
  const prefix = `features.orders.table.columns`;
  const isPayin = operation === 'payin';
  const isPayout = operation === 'payout';
  const isManager = includes(
    [UserRole.Admin, UserRole.TechOperator, UserRole.Operator],
    params?.role,
  );

  return [
    {
      header: t(`${prefix}.id`),
      valueGetter: (item) => (
        <div>
          <CopyTextId id={item.id} />
          <DateLabel>{formatUtils.formatDate(item.createdAt)}</DateLabel>
          {isManager && isPayin && (
            <P2PProviderStatus order={item as PayinOrder} sx={{ mt: 1 }} />
          )}
        </div>
      ),
    },
    {
      header: t(`${prefix}.currency_rate`),
      valueGetter: (item) => {
        if (
          hideSensitiveData({
            isPayout,
            role: params?.role,
            status: item.status,
          })
        ) {
          return null;
        }

        if (isPayin && item.originCurrencyRate && isManager) {
          const payinOrder = item as PayinOrder;
          return (
            <Fragment>
              {payinOrder.warnings?.currencyRate && (
                <WarningIcon className="tw-mr-1" color="warning" />
              )}
              <Money
                value={payinOrder.currencyRate}
                fiatCurrencyId={payinOrder.fiatCurrencyId}
                symbol
              />
            </Fragment>
          );
        }

        return (
          <Money
            value={item.currencyRate}
            fiatCurrencyId={item.fiatCurrencyId}
            symbol
          />
        );
      },
    },
    {
      header: t(`${prefix}.amount`),
      valueGetter: (item) =>
        hideSensitiveData({
          isPayout,
          role: params?.role,
          status: item.status,
        }) ? null : (
          <OrderAmountColumn order={item} />
        ),
    },
    {
      header: t(`${prefix}.trader_requisites`),
      valueGetter: (item) => renderOrderRequisites(item as PayinOrder),
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.TraderCard),
    },
    {
      header: t(`${prefix}.customer_id`),
      valueKey: 'customerId',
      hidden: !additionalColumns.includes(AdditionalOrderColumn.CustomerId),
    },
    {
      header: t(`${prefix}.customer`),
      valueGetter: (item) => (
        <UserInfo name={item.customerName} id={item.customerId} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Customer),
    },
    {
      header: t(`${prefix}.customer`),
      valueGetter: (item) => (
        <div>
          <UserInfo name={item.customerName} id={item.customerId} />
          <ContactInfo
            phone={item.customerPhone}
            email={item.customerEmail}
            telegram={(item as PayoutOrder).customerTelegram}
          />
        </div>
      ),
      hidden: !additionalColumns.includes(
        AdditionalOrderColumn.CustomerContacts,
      ),
    },
    {
      header: t(`${prefix}.customer_requisites`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        return (
          <Fragment>
            {(payinOrder?.paymentCustomerCardFirstDigits ||
              payinOrder?.paymentCustomerCardLastDigits) && (
              <div>
                {formatUtils.formatCardDigits({
                  firstDigits: payinOrder?.paymentCustomerCardFirstDigits,
                  lastDigits: payinOrder?.paymentCustomerCardLastDigits,
                })}
              </div>
            )}
            {payinOrder?.paymentCustomerPhoneLastDigits && (
              <div>
                {formatUtils.formatPhoneLastDigits(
                  payinOrder?.paymentCustomerPhoneLastDigits,
                )}
              </div>
            )}
          </Fragment>
        );
      },
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerRequisites),
    },
    {
      header: t(`${prefix}.customer_requisites`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        return (
          <Fragment>
            {(payinOrder?.paymentCustomerCardFirstDigits ||
              payinOrder?.paymentCustomerCardLastDigits) && (
              <div>
                {formatUtils.formatCardDigits({
                  firstDigits: payinOrder?.paymentCustomerCardFirstDigits,
                  lastDigits: payinOrder?.paymentCustomerCardLastDigits,
                })}
              </div>
            )}
            {payinOrder?.paymentCustomerUtr && (
              <div>{payinOrder.paymentCustomerUtr}</div>
            )}
          </Fragment>
        );
      },
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerRequisites),
    },
    {
      header: t(`${prefix}.customer_requisites`),
      valueGetter: (item) => {
        const payoutOrder = item as PayoutOrder;
        const hideRequisites =
          payoutOrder.status === PayoutOrderStatus.TraderAccept &&
          params?.role === UserRole.Trader;

        if (hideRequisites) {
          return null;
        }

        return (
          <RequisitesInfo
            cardholder={payoutOrder?.customerCardholder}
            bankId={payoutOrder?.bankId}
            paymentTypeId={payoutOrder?.paymentTypeId}
            cardInfo={payoutOrder?.customerCard}
            phone={payoutOrder?.customerPhone}
            swiftBic={payoutOrder?.customerSwiftBic}
            bic={payoutOrder?.customerBic}
            idCard={payoutOrder?.customerIdCard}
            beneficiaryName={payoutOrder?.customerBeneficiaryName}
            accountNumber={payoutOrder?.customerAccountNumber}
            email={payoutOrder?.customerEmail}
            name={payoutOrder?.customerName}
            expirationDate={payoutOrder?.customerExpirationDate}
          />
        );
      },
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerRequisites),
    },
    {
      header: t(`${prefix}.payment_date`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        return (
          isCustomerPaid(payinOrder) && (
            <Fragment>
              <DateLabel>
                {formatUtils.formatDate(
                  getStatusDate(payinOrder, OrderStatus.TraderConfirm),
                )}
              </DateLabel>
              {(payinOrder?.paymentCustomerCardFirstDigits ||
                payinOrder?.paymentCustomerCardLastDigits) && (
                <div>
                  {formatUtils.formatCardDigits({
                    firstDigits: payinOrder?.paymentCustomerCardFirstDigits,
                    lastDigits: payinOrder?.paymentCustomerCardLastDigits,
                  })}
                </div>
              )}
              {payinOrder?.paymentCustomerPhoneLastDigits && (
                <div>
                  {formatUtils.formatPhoneLastDigits(
                    payinOrder?.paymentCustomerPhoneLastDigits,
                  )}
                </div>
              )}
              {payinOrder?.paymentTypeId && (
                <PaymentTypeLabel paymentTypeId={payinOrder.paymentTypeId} />
              )}
              {payinOrder?.paymentCustomerBank && (
                <div>{payinOrder.paymentCustomerBank}</div>
              )}
              {payinOrder?.paymentCustomerName && (
                <div>{payinOrder.paymentCustomerName}</div>
              )}
              {payinOrder?.paymentCustomerIBAN && (
                <div>{payinOrder.paymentCustomerIBAN}</div>
              )}
              {payinOrder?.paymentCustomerAccountNumber && (
                <div>{payinOrder.paymentCustomerAccountNumber}</div>
              )}
              {payinOrder?.paymentCustomerUtr && (
                <div>{payinOrder.paymentCustomerUtr}</div>
              )}
            </Fragment>
          )
        );
      },
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerPayment),
    },
    {
      header: t(`${prefix}.payment_date`),
      valueGetter: (item) => {
        const payoutOrder = item as PayoutOrder;
        return (
          isTraderPaid(payoutOrder) && (
            <Fragment>
              <DateLabel>
                {formatUtils.formatDate(
                  getStatusDate(payoutOrder, OrderStatus.Completed),
                )}
              </DateLabel>
            </Fragment>
          )
        );
      },
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.TraderPayment),
    },
    {
      header: t(`${prefix}.shop`),
      valueGetter: (item) => (
        <UserInfo name={item.shop?.name} id={item.shopId} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Shop),
    },
    {
      header: t(`${prefix}.trader`),
      valueGetter: (item) => (
        <UserInfo name={item.trader?.user.name} id={item?.traderId} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Trader),
    },
    {
      header: t(`${prefix}.bank`),
      valueKey: 'bank',
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Bank),
    },
    {
      header: t(`${prefix}.confirm_date`),
      valueGetter: (item) =>
        isCustomerPaid(item as PayinOrder) && (
          <DateLabel>
            {formatUtils.formatDate(
              getStatusDate(item as PayinOrder, OrderStatus.Completed),
            )}
          </DateLabel>
        ),
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.ConfirmationDate),
    },
    {
      header: t(`${prefix}.merchant`),
      valueGetter: (item) => (
        <UserInfo name={item.merchant?.user.name} id={item?.merchantId} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Merchant),
    },
    {
      header: t(`${prefix}.status`),
      valueGetter: (item) => <OrderStatusColumn order={item as PayinOrder} />,
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.StatusActive),
    },
    {
      header: t(`${prefix}.status`),
      valueGetter: (item) => (
        <PayoutOrderStatusColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.StatusActive),
    },
    {
      header: t(`${prefix}.reason`),
      valueGetter: (item) =>
        getCancelOrderReason((item as PayinOrder).statusDetails),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.CancelReason),
    },
    {
      header: t(`${prefix}.dispute`),
      valueClassName: 'tw-w-[160px]',
      valueGetter: (item) => (
        <OrderResolveDisputeColumn order={item as PayinOrder} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.ResolveDispute),
    },
    {
      header: t(`${prefix}.dispute`),
      valueClassName: 'tw-w-[240px]',
      valueGetter: (item) =>
        (item as PayinOrder).statusDetails &&
        orderUtils.getStatusDetailsLabel((item as PayinOrder).statusDetails),
      hidden:
        !isPayin || !additionalColumns.includes(AdditionalOrderColumn.Dispute),
    },
    {
      header: t(`${prefix}.dispute`),
      valueClassName: 'tw-w-[240px]',
      valueGetter: (item) => (
        <PayoutOrderDisputeColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout || !additionalColumns.includes(AdditionalOrderColumn.Dispute),
    },
    {
      header: t(`${prefix}.actions`),
      valueClassName: 'tw-w-[120px]',
      valueGetter: (item) => (
        <OrderCancelledToDisputeColumn order={item as PayinOrder} />
      ),
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CancelledToDispute),
    },
    {
      header: t(`${prefix}.actions`),
      valueClassName: 'tw-w-[120px]',
      valueGetter: (item) => (
        <PayoutOrderCancelledToDisputeColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.CancelledToDispute),
    },
    {
      header: t(`${prefix}.automation_status`),
      valueClassName: 'tw-w-[25px]',
      valueGetter: (item) => <AutomationStatus order={item as PayinOrder} />,
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.AutomationStatus),
    },
    {
      header: t(`${prefix}.validation`),
      valueClassName: 'tw-w-[120px]',
      valueGetter: (item) => (
        <PayoutOrderValidationColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.Validation),
    },
  ];
};

const hideSensitiveData = (params: {
  isPayout: boolean;
  role?: UserRole;
  status: string;
}): boolean =>
  params.isPayout &&
  params.role === UserRole.Trader &&
  params.status === PayoutOrderStatus.TraderAccept;

const getCancelOrderReason = (details: OrderStatusDetails) => {
  const { t } = i18next;
  const key = `order_cancelation_reason.${details}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const isStatusTimeoutOver = (order: Order) =>
  order.statusTimeoutAt && moment(order.statusTimeoutAt).isBefore(new Date());

const getExportOrdersColumnLabel = (column: string) =>
  i18next.t(`export_orders_column.${column}`, { defaultValue: column });

const isCrossFiatPayment = (order: Order) =>
  !!order.crossFiatCurrencyId && !!order.crossFiatCurrencyRate;

export const orderUtils = {
  getCommonFilters,
  getCommonPayoutOrderFilters,
  getStatusFilterDefinition,
  getCallbackUrlStatusLabel,
  getStatusLabel,
  getStatusDate,
  getStatusDetailsLabel,
  getPayoutStatusDetailsLabel,
  getPayinOrderColumns,
  getPayoutOrderColumns,
  isStatusTimeoutOver,
  isPaid: isCustomerPaid,
  getExportOrdersColumnLabel,
  isCrossFiatPayment,
  renderP2PProviderOrderRequisites,
};
